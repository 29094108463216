import React, { FunctionComponent, useState } from 'react';
import Layout from '../../components/layout';
import axios from 'axios';
import Modal from '../../components/modal';
import './styles.scss';

const ContactPage = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    isModalOpen: false,
    error: false,
    errorMessage: '',
  });

  const submitHandler = async e => {
    e.preventDefault();
    setLoading(true);
    const { data } = await axios({
      method: 'post',
      url: 'https://api.hexometer.com/v2/ql',
      data: {
        operationName: 'ContactFormSubmit',
        variables: {
          name: formData.name,
          email: formData.email,
          subject: `PullContacts: ${formData.subject}`,
          message: JSON.stringify(formData.message),
          emailTo: 'support@hexometer.com',
          productType: 'pullcontacts',
        },
        query:
          'mutation ContactFormSubmit($name: String!, $email: String!, $subject: String!, $message: String!, $emailTo: String) {\n  contactFormSubmit(name: $name, email: $email, subject: $subject, message: $message, emailTo: $emailTo) {\n    error\n    message\n    __typename\n  }\n}\n',
      },
    });
    setLoading(false);
    if (data?.data?.contactFormSubmit.error) {
      // data.data.contactFormSubmit.message
      setFormData({
        ...formData,
        error: true,
        isModalOpen: true,
        errorMessage: 'Something went wrong, please try again.',
      });
    } else {
      // successfuly
      setFormData({
        ...formData,
        isModalOpen: !formData.isModalOpen,
        error: false,
        errorMessage: '',
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    }
  };

  const changeHandler = event => {
    const target = event.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const modalToggle = async () => {
    await setFormData({
      ...formData,
      isModalOpen: !formData.isModalOpen,
    });
    // toggleLastURL()
  };

  return (
    <Layout
      title={'Customer support contacts | Pull Contacts'}
      metaName={'description'}
      metaContent={'Customer support contacts - Search for Emails, Phone Numbers and Social Links'}
      hideIllustrations={false}
    >
      <Modal
        isOpen={formData.isModalOpen}
        modalToggle={modalToggle}
        title={formData.errorMessage || 'Your message has been sent, we will come back to you shortly.'}
        onButtonClick={modalToggle}
        buttonText={'Close'}
      />
      <div className="contact-block">
        <div className="main container">
          <div className="page-hero">
            <h2 className="page-title">Feel free to contact us</h2>
          </div>
          <form className="contact_form_box" onSubmit={e => !loading && submitHandler(e)}>
            <div className="row">
              <div className="col-lg-6">
                <input
                  required
                  type="text"
                  className="input"
                  placeholder={'Your name'}
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-6">
                <input
                  required
                  type="email"
                  className="input"
                  placeholder={'Your email'}
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-12">
                <input
                  required
                  type="text"
                  className="input"
                  placeholder={'Your Subject'}
                  name="subject"
                  value={formData.subject}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-12">
                <textarea
                  required
                  className="textarea"
                  rows={5}
                  onChange={changeHandler}
                  name="message"
                  placeholder={'Type your message here'}
                  value={formData.message}
                />
              </div>
              <div className="col-lg-12 footer-block">
                <button className="button" disabled={loading}>
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
